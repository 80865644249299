
// import fire from '../fire';
import axios from 'axios';
import { getEnv } from './helper';

// const lineNoti = fire.functions().httpsCallable('notificationLine');
export function goingPaidNoti(
  shopName, branchName, date, slot, tel,
  selectedInvoiceId,
) {
  const { isProd, url, adminUrl } = getEnv();
  const envLabel = isProd ? '' : '(ทดสอบ)';
  const message = `
  ## สอบถาม/โอนชำระ FoodTruck ${envLabel} ##
  จากร้าน: ${shopName}
  โทร: ${tel}
  สถานที่: ${branchName}
  วันที่: ${date}
  ตำแหน่ง: ${slot}
  ตรวจสอบ: ${adminUrl}/?ref=${selectedInvoiceId}`;
  return axios.post(
    `${url}/notificationLine`, {
    message,
  }, {
    headers: { 'Access-Control-Allow-Origin': '*' }
  }
  );
}

export function ticketNoti(
  id, shopName, tel, total
) {
  const { isProd, url } = getEnv();
  const envLabel = isProd ? '' : '(ทดสอบ)';
  const message = `
  ## ใช้ Voucher FoodTruck ${envLabel} ##
    จากร้าน: ${shopName}
    โทร: ${tel}
    จำนวนที่ใช้ทั้งหมด: ${total} code
    รหัส: ${id}`;
  return axios.post(
    `${url}/notificationLine`, {
    message,
  }, {
    headers: { 'Access-Control-Allow-Origin': '*' }
  }
  );
}

export function bookNoti(
  shopName, branchName, date, time = [], slot, tel,
) {
  const { isProd, url } = getEnv();
  const envLabel = isProd ? '' : '(ทดสอบ)';
  const message = `
  ## จองพื้นที่ FoodTruck ${envLabel} ##
  จากร้าน: ${shopName}
  โทร: ${tel}
  สถานที่: ${branchName}
  วันที่: ${date}
  เวลา: ${time.join(', ')}
  ตำแหน่ง: ${slot}`;
  return axios.post(
    `${url}/notificationLine`, {
    message,
  }, {
    headers: { 'Access-Control-Allow-Origin': '*' }
  }
  );
}

export function preRegisNoti(
  shopName, tel, interested = [],
) {
  const { isProd, url } = getEnv();
  const envLabel = isProd ? '' : '(ทดสอบ)';
  const message = `
  ## รออนุมัติ FoodTruck ${envLabel} ##
  จากร้าน: ${shopName}
  โทร: ${tel}
  สนใจ: ${interested.join(' ')}`;
  return axios.post(
    `${url}/notificationLine`, {
    message,
  }, {
    headers: { 'Access-Control-Allow-Origin': '*' }
  }
  );
}

export function regisEventNoti(
  shopName, tel, project,
) {
  const { isProd, url } = getEnv();
  const envLabel = isProd ? '' : '(ทดสอบ)';
  const message = `
  ## สมัคร FoodTruck@Home ${envLabel} ##
  จากร้าน: ${shopName}
  โทร: ${tel}
  โครงการ: ${project}`;
  return axios.post(
    `${url}/notificationLine`, {
    message,
  }, {
    headers: { 'Access-Control-Allow-Origin': '*' }
  }
  );
}

export default {
  bookNoti, preRegisNoti, regisEventNoti,
}